<nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color: #FFFFFF; border: 0;">
  <div class="container-fluid">
    <a class="navbar-brand" style="font-size: 28px; padding: 0; padding-right: 15px;" routerLink="/" routerLinkActive="active"><img style="height: 45px;" alt="Gister Portal" src="assets/images/Portail_ND-150FondTransparent.png"><span class="veilletitle">Le serveur de la veille packaging</span></a>

    <ul class="navbar-nav ">
      <li class="nav-item"><a class="nav-link active" href="https://naturaldevelopment.fr/help/" target="_blank">Help</a></li>
      <li class="nav-item dropdown" *ngIf="loginService.isAdmin"><a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Administration <span class="caret"></span></a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item" href="#" routerLink="/uploaddoc" routerLinkActive="active">Upload files</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="javascript:void(0)" (click)="solraction('delta')">Solr delta Indexation </a></li>
          <li><a class="dropdown-item" href="javascript:void(0)" (click)="solraction('full')">Solr full Indexation</a></li>
        </ul></li>

      <!-- User logged in => user menu -->
      <li class="nav-item dropdown" *ngIf="loginService.isLogged"><a href="#" class="nav-link dropdown-toggle" id="navbarDropdown2" data-toggle="dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{loginService.user.name}}&nbsp;({{loginService.levellabel[loginService.level]}})<span class="caret"></span></a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
          <li><a class="dropdown-item" href="#" (click)="genthumb()" *ngIf="loginService.isAdmin">Generate thumbs.</a></li>
          <li><a class="dropdown-item" href="#" (click)="loginService.logout()">Logout</a></li>
        </ul></li>

    </ul>
  </div>
</nav>

<div *ngIf="show" class="container alert fade in" [class.alert-success]="issuccess" role="alert">{{txt}}</div>

<!-- Thumb gen result -->
<div *ngIf="genthumbresult" class="alert alert-primary alert-dismissible show" role="alert">
  <p>Thumb gen result</p>
  <ul *ngFor='let d of genthumbresult' class="list-group list-group-horizontal">
    <li class="list-group-item">{{ d.doc }}</li>
    <li class="list-group-item" [ngClass]="{'list-group-item-danger':d.result!=='SKIPPED'}">{{ d.result }} </li>
  </ul>
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>