import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from './../environments/environment';

import { LoginService } from './login.service';

@Injectable({
	providedIn: 'root'
})
export class UploaddocService {

	constructor(public http: HttpClient, private loginService: LoginService) { }

	public sendformdata(url: string, gp: number, files: Array<File>, indpurge: boolean) {
		return new Promise((resolve, reject) => {
			var formData: any = new FormData()
			var xhr = new XMLHttpRequest()

			for (var i = 0; i < files.length; i++) {
				formData.append("uploads[]", files[i], i + '-' + files[i].name) // We concat the index to the filename to be able to retrieve it in the response 
			}
			formData.append("gp", gp)
			formData.append("indpurge", (indpurge ? 1 : 0))

			xhr.onreadystatechange = function() {
				if (xhr.readyState == 4) {
					if (xhr.status == 200) {
						resolve(JSON.parse(xhr.response))
					} else {
						reject(xhr.response)
					}
				} else if (xhr.readyState < 4) {
					console.log("xhr.readyState = " + xhr.readyState)
				}
			}
			xhr.open("POST", url, true)
			xhr.setRequestHeader('Authorization', `Bearer ${this.loginService.getToken()}`)
			xhr.send(formData)
		});
	}

	public genthumb(callback: Function) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.loginService.getToken()}`
			}),
			responseType: 'json' as const
		};

		this.http.get(environment.service_genthumb, httpOptions)
			.subscribe((data: any) => {
				// console.log(JSON.stringify(data));
				callback(data);
			});
	}

}
