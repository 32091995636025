import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';


import { environment } from '../../environments/environment';

import { SearchresultService } from '../searchresult.service';
import { LoginService } from '../login.service';
import { UploaddocService } from '../uploaddoc.service';
import { TagService } from '../tag.service';
import { SolrResponse } from '../shared/solrResponse';
import { Doc } from '../shared/doc';

declare var jQuery: any  // Fake declare to avoid compile errors
declare var Spinner: any

@Component({
	selector: 'app-searchresult',
	templateUrl: './searchresult.component.html',
	styleUrls: ['./searchresult.component.css'],
	providers: [SearchresultService]
})
export class SearchresultComponent implements OnInit, AfterViewInit {

	public alert: string = "Enter search terms"
	public isDanger: boolean = false

	@Input() public searchbox: string = "";  // Search criteria bidi binding
	public sellang: string = "*";
	public selsource: string = "*";
	public sensibility: string = "s4";
	public datdeb: string;
	public datfin: string;
	public groups: Array<any>;
	public tags0: Array<any>;		// Button type tags
	public tags1: Array<any>;		// Hashtags style links
	public selectedGroup: number = 1   // Forced to default
	public checkall: boolean = false;
	public indmessage: boolean = false;
	public searchtip: string = "<b>Search tips in Full mode</b><br/><ul><li>Choose search terms that are closely related to the topic of interest.<br/>All search terms will be retrieved in the whole document (default \"AND\" connector).</li><li>Use quotation mark for phrase search. Ex: <i>low cost production</i>.</li><li>Combine search terms with Boolean operators (AND, OR, NOT) and parenthesis.<br/>Ex: <i>(material OR alloy) AND \"BASF GROUP\"</i>.</li><li>Use wildcard characters to find variations of search terms.<br/>Ex: <i>over*charg*</i> will find overcharging, overdischarge...</li><li>Use the specific date and country fields to limit your search results.</li></ul><b>Search tips in Easy mode</b><ul><li>Use natural language to describe the topic of interest. Results are carried out according to<br/>an algorithm that automatically adjusts the proximity of the search terms.</li><li>In Easy mode, Boolean operators, wildcard characters and phrase search are not supported.</li><li>Use the specific date and country fields to limit your search results.</li></ul>"
	public memberalert: boolean = false;
	public freeonly: boolean = false;

	public service_getattach = environment.service_getattach;
	public service_getfulltext = environment.service_getfulltext;
	public service_download = environment.service_download;

	public docs: Array<Doc> = [];

	private debug: string;

	private solrResponse: SolrResponse;
	private solr_rows: number = 10000;	// Solr number of rows returned. TODO parametres file
	private solr_start: number = 0;     // Solr start row	

	constructor(public searchresultService: SearchresultService, public tagService: TagService, public loginService: LoginService, private router: Router, private route: ActivatedRoute, private elementRef: ElementRef, public uploaddocService: UploaddocService) {
		console.log('SearchresultComponent constructor');
	}

	ngOnInit(): void {
		this.searchresultService.solrResponse$.subscribe({ // Subscribe to solrResponse$ Observable
			next: (v) => {
				this.updateDocs(v);       // And fill the docs array on modification
			}
		});

		this.loginService.loginSubject$.subscribe({ // Subscribe to loginSubject$ Observable
			next: (kw) => {
				console.log("loginSubject.next: " + kw);
				if (kw && kw !== '') {
					this.searchbox = kw;
					this.onSubmit();
				}
			}
		});

		let kw = this.route.snapshot.params["keyword"];
		console.log('SearchresultComponent ngOnInit - keyword: ' + kw);
		if (typeof kw !== 'undefined') {
			console.log('XXXXXXXXXXX SHOULD NOT HAPPEN XXXXXXXXXX ' + typeof kw);
			this.loginService.kw = kw;
			this.loginService.loginSubject.next(kw);
		}

		this.tagService.gettags(0, (data) => {
			this.tags0 = data;
		});

		this.tagService.gettags(1, (data) => {
			this.tags1 = data;
		});

		// this.uploaddocService.genthumb();	// FIXME launch test
	}


	ngAfterViewInit() {
		console.log('SearchresultComponent ngAfterViewInit');
		/* Removed suggestion functionalities. See previous version */
	}

	public onSubmit(): void {  // Search form submit => call search service
		// jQuery(this.elementRef.nativeElement).find('#searchbox').typeahead('close');
		this.docs = []   // Empty result

		this.indmessage = (this.sensibility == 's2' && this.searchbox.search(/\*/) != -1)     // ?
		if (this.indmessage) return

		if (this.sensibility == 's4') {
			this.searchbox = this.searchbox.replace(/\sand\s/g, " AND ")
			this.searchbox = this.searchbox.replace(/\sor\s/g, " OR ")
			this.searchbox = this.searchbox.replace(/\snot\s/g, " NOT ")
		}
		this.loginService.canActivate();

		/*		let target = jQuery(this.elementRef.nativeElement).find('#spinnerdiv')[0];  // Spinner target
				let spinner = new Spinner().spin(target);    // Spin it!*/
		this.searchresultService.search(this.searchbox, this.selectedGroup, this.selsource, this.sellang, this.sensibility, this.datdeb, this.datfin, this.solr_start, this.solr_rows, this.freeonly, (numFound) => {
			// spinner.stop()
			if (numFound < 0) {
				this.alert = "Server error"
				this.isDanger = true
			} else if (numFound == 0) {
				this.alert = "No match"
				this.isDanger = false
			} else {
				this.alert = null
				this.isDanger = false
			}
		});
	}

	public onDoclink(doc: string, docveille: boolean): void {
		if (docveille && this.loginService.user.level > 1) {
			this.memberalert = true;
		} else {
			let url = environment.service_getdoc + '/' + doc + '?token=' + this.loginService.getToken();
			this.router.navigate([]).then(result => { window.open(url, '_blank'); });
		}
	}

	public onAlertclose() {
		this.memberalert = false;
	}

	public onQuery(s: string): void {
		console.log("Query: " + s);
		this.searchbox = s;
		this.onSubmit();
		// this.searchbox=lib;
		// this.searchbox="";
	}

	public onKeypressed(newValue): void {  // Try to avoid forbidden chars
		//console.dir(newValue)
		let key = newValue.key
		// let notauth=/\*|\+|\(|\)|~|-| AND | OR /i
		let notauth = /\*|\+|\(|\)|~| AND | OR /i
		//console.log("--> " + newValue.key)
		this.indmessage = (this.sensibility == 's2' && (this.searchbox.search(notauth) != -1 || key.search(notauth) != -1))
		if (this.indmessage) {
			// jQuery(this.elementRef.nativeElement).find('#message').show()
		}
		else {
			// jQuery(this.elementRef.nativeElement).find('#message').hide()
		}
	}

	public onBtclear(): void {
		this.searchbox = '';
		this.datdeb = null;
		this.datfin = null;
		this.selsource = '*';
		this.alert = null
		// jQuery(this.elementRef.nativeElement).find('#searchbox').typeahead('val', '');
	}

	public zipdownload(): void {
		console.log("zipDownload, nb docs: " + this.docs.length);

		var sdocs: Array<Doc> = [];

		for (var i = 0; i < this.docs.length; i++) {
			if (this.docs[i].checked) {
				// console.log(this.docs[i].doc);
				sdocs.push(new Doc(this.docs[i].doc, this.docs[i].pdfname, null, this.docs[i].defattach, null, null, null, null, null, null, null))
			}
		}
		this.searchresultService.zipdownload(sdocs);
	}

	private updateDocs(sr: SolrResponse): void {
		// this.solrResponse = sr;
		// this.debug = JSON.stringify( sr );
		// console.log( "sr: " + JSON.stringify( sr ) );
		for (var i = 0; i < sr.response.docs.length; i++) {
			let docId: number = sr.response.docs[i].id;
			let header, hl, title, tl
			hl = sr.highlighting[docId]
			if (this.searchbox == '' || this.searchbox == null || !hl.hasOwnProperty('header')) header = sr.response.docs[i].header
			else header = hl.header[0]

			if (this.searchbox == '' || this.searchbox == null || !hl.hasOwnProperty('title')) title = sr.response.docs[i].title
			else title = hl.title[0]

			let _doc = new Doc(String(docId),
				sr.response.docs[i].pdfname,
				(sr.response.docs[i].data ? JSON.parse(sr.response.docs[i].data) : {}),
				sr.response.docs[i].defattach, header,
				sr.response.docs[i].source, title, sr.response.docs[i].lang, '', sr.response.docs[i].dat, sr.response.docs[i].rawcontent_b);
			this.docs.push(_doc);
			// console.log(JSON.stringify(_doc));
		}
	}

	public selectAll(): any {
		//console.log("selectAll");
		this.checkall = !this.checkall
		for (var i = 0; i < this.docs.length; i++) {
			this.docs[i].checked = this.checkall
		}
		// jQuery("[type=checkbox]").prop('checked', true);
	}

	public getDoi(d: Doc) {
		if (!d.hasOwnProperty('data') || !d.data.hasOwnProperty('DO')) {
			console.log('No DO property')
			//console.dir(d)
			return '';
		}
		let a: string[] = d.data['DO'].split(/\//)
		let tail = a.pop()
		let head = a.pop()
		return head + '/' + tail
	}
}
