
<div class="row justify-content-md-center">
  <div class="col-xs-12 col-md-6 ">
    <form id="loginform" class="card card-body bg-light" (ngSubmit)="onSubmit()">
      <div class="text-center">
        <h3 style="padding-bottom: 10px;">Portail ND</h3>
      </div>

      <div class="row mb-3 ">
        <label for="email" class="col-sm-6 col-form-label">Identifiant</label>
        <div class="col-sm-6">
          <input class="form-control" id="email" name="email" placeholder="email" [(ngModel)]="email">
        </div>
      </div>

      <br />

      <div class="row mb-3">
        <label for="password" class="col-sm-6  col-form-label">Mot de passe</label>
        <div class="col-sm-6">
          <input type="password" class="form-control" id="password" name="password" placeholder="Mot de passe" [(ngModel)]="password" (click)="onClick()">
        </div>
      </div>

      <input type="hidden" name="func" value="login" />
      <div class="row">
        <button id="loginbutton" type="submit" class="btn btn-primary col-4 offset-4">Connexion</button>
      </div>
      <div class="alert alert-danger" style="margin-top: 6px;" role="alert" *ngIf="loginService.wrongpassword">Invalid password, try again</div>
    </form>
  </div>
</div>
