import { Component, OnInit, NgZone } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { UploaddocService } from '../uploaddoc.service';

@Component({
	selector: 'app-uploaddoc',
	templateUrl: './uploaddoc.component.html',
	styleUrls: ['./uploaddoc.component.css']
})
export class UploaddocComponent implements OnInit {

	private zone: NgZone
	private response: any = {}

	public isinit = true        // Upload state management
	public isdragging = false
	public isuploading = false
	public isdone = false
	public iserror = false

	public uploadFile: any
	public options: Object

	public filesToUpload: Array<File>
	public groups: Array<any>
	public selectedGroup: number = 1
	public indpurge: boolean = false

	public uploadSubscription: Subscription;
	public uploadsubject = new Subject();
	public uploadObservable$: Observable<any> = this.uploadsubject.asObservable()

	constructor(public uploadDocService: UploaddocService) { }

	ngOnInit(): void {
	}

	public upload() {
		console.log("handleUpload. indpurge=" + this.indpurge);

		this.uploadsubject.next("startupload")
		/* FIXME
		
		
				let target = jQuery(this.elementRef.nativeElement).find('#leftblock')[0]
				let spinner = new Spinner().spin(target)
				jQuery(this.elementRef.nativeElement).find('.spinner').css({ top: 90 })    // Sorry, can't get to fix top property otherwise
*/
		this.isuploading = true
		this.isinit = false
		let self = this
		this.uploadDocService.sendformdata(environment.service_uploaddoc, this.selectedGroup, this.filesToUpload, this.indpurge)
			.then((result) => {
				console.dir(result)
				self.majfilestoupload(result)

				/*
				if (this.indpurge) this.solractionService.action('full', (ret) => { })   // Force Solr full import (we don't manage deleted keys) 
				else this.solractionService.action('delta', (ret) => { })                // Force Solr delta import
				spinner.stop()
				*/

				self.isuploading = false
				self.isdone = true
			},
				(error) => {
					console.error(error);
					self.isuploading = false
					self.iserror = true
				});
	}

	private majfilestoupload(result: any) {
		for (let i = 0; i < result.length; i++) {
			//let fileindex:number=Number(/([0-9]+)-.*/.exec(result[i].originalName))
			let match = (/([0-9]+)-.*/.exec(result[i].originalName))
			if (match.length > 0) {
				let fileindex: number = Number(match[1])
				if (!isNaN(fileindex)) {
					console.log(result[i].originalName + " " + fileindex)
					let file = this.filesToUpload[fileindex]
					file["status"] = result[i].status
					console.dir(file)
				} else {
					console.log("ERROR " + result[i].originalName + " " + fileindex + " Is not a number")
				}
			}
		}
	}

	public fileChangeEvent(fileInput: any) {    // Used by old fashion file open method 
		this.filesToUpload = <Array<File>>fileInput.target.files;
	}

	public drop(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.isdragging = false;
		for (let i = 0; i < event.dataTransfer.files.length; i++) {
			console.log(<Array<File>>event.dataTransfer.files[i])
			this.filesToUpload.push(event.dataTransfer.files[i])
		}
		//this.filesToUpload = <Array<File>> this.filesToUpload.concat(<Array<File>>event.dataTransfer.files[0])
	}

	public dragenter(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.isdragging = true;
	}

	public dragover(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.isdragging = true;
	}

	public dragleave(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.isdragging = false;
	}

	public dragend(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.isdragging = false;
	}

	public onBtremovefile(i: number): void {
		this.filesToUpload.splice(i, 1)
	}

	public formatSize(bytes: number): string {
		var i = -1;
		do {
			bytes = bytes / 1024;
			i++;
		} while (bytes > 99);

		return Math.max(bytes, 0.1).toFixed(1) + ['kB', 'MB', 'GB', 'TB', 'PB', 'EB'][i];
	}
}
