import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from './../environments/environment';

import { SolrResponse } from './shared/solrResponse';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	public loginSubject: Subject<string> = new Subject<string>();
	public loginSubject$ = this.loginSubject.asObservable();

	public user: any = null;
	public isLogged: boolean = false;
	public isAdmin: boolean = false;
	public level: number = -1;
	public wrongpassword: boolean = false;

	public levellabel: string[] = ["Admin", "Member", "Guest"]

	public kw: string;

	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
		// console.log("In LoginService constructor ");
		// this.checklogged();	// Update token
	}

	public login(email: string, password: string) {
		// console.log("In loginservice.login");
		this.makeLoginPost(email, password, (data) => {
			if (data) {	// Success!
				try {
					if (data.token) { // Login is OK
						localStorage.setItem('token', data.token);
						this.wrongpassword = false;
						localStorage.removeItem('login');			// Just in case login came from manual login
						this.loginSubject.next(this.kw);			// Triggers observable. Subscribed in SearchresultComponent
						// console.log("Login OK");
					} else {    // No token => invalid password
						this.wrongpassword = true;
						console.log("invalid password");
					}
					this.checklogged()  // Update login properties
				}
				catch (e) {
					console.error("Exception raised : parsing error!!", e);
				}
			} else {	// TODO Login service failed
				console.error("Login service failed");
			}
		});
	}

	public logout(): any {
		localStorage.removeItem('token');
		localStorage.setItem('login', 'LOGIN');	// Flag a login request (not so clean)
		this.checklogged();  									// Update login properties
	}

	private makeLoginPost(email: string, password: string, cb: Function): void {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		this.http.post(
			environment.service_login,
			JSON.stringify({
				func: 'LOGIN',
				email: email,
				password: password
			}),
			httpOptions)
			.subscribe((res: any) => {
				// console.log("makeLoginPost response : " + JSON.stringify(res));
				cb(res);
			});
	}

	public getToken(): any {    // Used by various services in the "bearer" http param
		if (localStorage.getItem('token') == 'undefined') { // In case token is corrupted (null)
			localStorage.removeItem('token');
			return null;
		}
		return localStorage.getItem('token');
	}

	private renew() {
	}

	/**
	 * Is user logged?
	 * Update user, isLogged and isAdmin properties from localStorage token, return isLogged
	 * Called whenever a property needs update (login, logout, renew)
	 */
	private checklogged(): boolean {   // TODO horrible function w/ 3 exits and side effects
		console.log("checklogged() called (expensive one)");
		let token = localStorage.getItem('token')
		this.user = (token == null ? null : this.parseJwt(token))   // TODO test invalid token

		if (!this.user || !this.user.user) this.user = null       // user is null OR id is null (why token != null??) => not connected

		if (this.user == null) {
			console.log("  checklogged() test. No Token")
			this.isLogged = false;
			this.isAdmin = false;
			this.level = -1;
			return false; // No token 
		}
		let exp: Date = new Date(this.user.exp);
		let now: Date = new Date();

		if (this.user.exp > now.getTime()) {   // Token not yet expired, OK 
			console.log("  checklogged() test. Token Valid until " + exp);
			this.isLogged = true;
			this.isAdmin = (this.user.level === 0);
			this.level = this.user.level;
			return true;
		} else {    // Expired token
			console.log("  checklogged() test. Expired Token");
			console.log("  Date, exp date: " + now + ', ' + exp);
			this.isLogged = false;
			this.isAdmin = false;
			this.level = -1;
			return false;
		}
	}

	private parseJwt(token: string): any {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(atob(base64));
	}

	public canActivate() {
		console.log('AuthGuard#canActivate called (expensive)');

		if (this.checklogged()) {    // Valid token, OK and renew
			this.renew();
			return true;
		} else {    // Invalid or expired => force login
			// console.log( "Invalid token" )
			/*if (this.kw !== '') this.router.navigate( ['/search/' + this.kw] );
			else this.router.navigate( ['/search'] );*/
			return false;
		}
	}
}
