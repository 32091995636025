import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { NavbarService } from '../navbar.service';
// import { SolractionService } from '../solraction/solraction.service';
import { LoginService } from '../login.service';
import { UploaddocService } from '../uploaddoc.service';

import { environment } from '../../environments/environment';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	public show: boolean = false
	public issuccess: boolean = false
	public isdanger: boolean = false
	public type: string
	public txt: string

	genthumbresult: Array<any>;

	constructor(public navbarService: NavbarService, public router: Router, public loginService: LoginService, public uploaddocService: UploaddocService, private dialog: MatDialog) { }

	ngOnInit(): void {
	}

	private uploaddoc(): void {
		// this.navbarService.uploaddoc(); // FIXME
	}


	public solraction(action: string): void {
		// this.router.navigate( ['/solraction/', 'reindexfull', mode, ++this.count] );
		/*		this.solractionService.action(action, (ret) => {    // Todo test result, store solR response for futur use 
					this.message('success', 'Indexation terminée avec succés')
				});*/
	}

	public genthumb(): void {
		// this.uploaddocService.genthumb();
		/*const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
		this.dialog.open(GenthumbComponent, dialogConfig);*/
		this.uploaddocService.genthumb((data) => { this.genthumbresult = data; });
	}

	public message(type: string, txt: string): void {
		this.type = type;
		this.txt = txt;
		if (type == 'success') {
			this.issuccess = true;
			this.isdanger = false;
		}
		this.show = true;
		//wait 3 Seconds and hide
		setTimeout(function() {
			this.show = false;
		}.bind(this), 2000);
	}
}
