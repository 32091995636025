<ng-container *ngIf="!loginService.isLogged">
<div id="" class="h-100 row align-items-center container-fluid">
  <div *ngIf="!loginService.isLogged" class="col-md-12">
    <app-login style="margin-left:50%"></app-login>
    <br />
    <p>
      Pas encore de compte ? <a href="http://naturaldevelopment.fr">Contactez-nous</a>
    </p>
  </div>
</div>
</ng-container>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;"> <!-- graphic resources --> 
  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
  </symbol>
  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
  </symbol>
  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
  </symbol>
</svg>

<ng-container *ngIf="loginService.isLogged">
<div class="card card-body bg-light">

  <!-- Search area -->
  <form id="searchform" class="" role="search">
    <!-- Search terms & OK / Cancel buttons -->
    <div class="row">
      <div class="col-8">
        <input id="searchbox" name="searchbox" type="text" class="form-control" value="" placeholder="Search terms" [(ngModel)]="searchbox" #_searchbox (keypress)="onKeypressed($event)">
      </div>
      <div class="col-4">
        <div class="row" style="column-gap: 4px;">
          <button id="btsearch" type="submit" class="btn col btn-outline-success btn-sm" (click)="onSubmit()">
            <span><mat-icon aria-hidden="false">check</mat-icon></span>
          </button>
          <button id="btclear" type="button" class="btn col btn-outline-secondary btn-sm" (click)="onBtclear()">
            <span><mat-icon aria-hidden="false">close</mat-icon></span>
          </button>
          <mat-checkbox class="btn col" name="cb-freeonly" [(ngModel)]="freeonly">Documents&nbsp;libres</mat-checkbox>
        </div>
      </div>
    </div>
  </form>

  <div class="text-center buttontags" style="margin-top: 20px; margin-bottom: 20px;">
    <!-- Buttons -->
    <ng-container class="tag" *ngFor='let tag of tags0; '>
    <button type="button" class="btn btn-outline-success btn-sm" (click)="onQuery(tag.link)">{{ tag.lib }}</button>
    &nbsp; </ng-container>
  </div>

  <div id="tag1" class="text-center hashtags">
    <ng-container class="tag" *ngFor='let tag of tags1; '>
    <button type="button" class="btn btn-link" (click)="onQuery(tag.link)">{{ tag.lib }}</button>
    &nbsp; </ng-container>
    <!-- Hashtags -->
  </div>

  <div id="spinnerdiv"></div>
</div>

<div class="" style="margin-top: 10px;" *ngIf="docs.length>0">
  <!-- Action menu & nb of results -->
  <div class="d-flex justify-content-between bd-highlight">
    <div *ngIf="loginService.level<2" class="dropdown">
      <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-bs-toggle="dropdown" aria-expanded="false">Actions</button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li><a class="dropdown-item" style="cursor: pointer;" (click)="selectAll()">Select/Deselect all</a></li>
        <li><a class="dropdown-item" style="cursor: pointer;" (click)="zipdownload()" target="_blank" download>Download zip file</a></li>
      </ul>
    </div>
    <h6>
      Results:&nbsp;<span class="badge bg-info text-dark">{{docs.length}}</span>
    </h6>
    <div>&nbsp;</div>
  </div>

  <!-- Docs -->
  <form id="resultform" target="_blank">
    <div class="row docrow" *ngFor="let _doc of docs">

      <div *ngIf="loginService.user.level<2" class="col-lg-1 col-xs-1 btn-group-vertical" role="group" data-toggle="buttons" style="">
        <a *ngIf="false && loginService.isAdmin" role="button" class="btn btn-default" style="margin-top: 10%;" routerLink="/editdoc/{{_doc.doc}}" routerLinkActive="active"><span class="glyphicon glyphicon-cog"></span></a> <input *ngIf="loginService.user.level<2" style="margin-top: 50%; margin-left: 50%" type="checkbox" name="cbdoc[]" [(ngModel)]="_doc.checked" value="{{ _doc.pdfname }}">
      </div>

      <div class="col">
        <button type="button" (click)="onDoclink(_doc.doc, _doc.docveille)" class="list-group-item text-start" data-href="" style="width:100%;">
          
          <div class="row align-items-center">
            <div class="col-md-auto">
          
              <img class="media-object thumb" style="max-width: 200px;" src="{{service_download + '/thumb/thumb' + _doc.doc+ '_1.png'}}">
          
            </div>
            <div class="col">
          
          
              <!-- Body: title, author, header -->
              <div class="">
                <!-- First line: source-dat fulltext link -->
                <span>{{ _doc.getYear()}} {{ _doc.pdfname}}</span> <a *ngIf="loginService.isAdmin && !_doc.rawcontent_b" href="{{'http://sci-hub.io/'+getDoi(_doc)}}" target="_blank" class="pull-right"><i class="glyphicon glyphicon-euro"></i> Get full text</a> <a *ngIf="false && _doc.rawcontent_b" href="{{service_getfulltext + '/' + _doc.doc}}" target="_blank" class="pull-right"><i
                  class="glyphicon glyphicon-cloud-download"></i> Full text available</a>
              </div>
              <p class="doctitle darkgrey" [innerHTML]="_doc.title"></p>
              <p class="docsource">
                <strong>{{_doc.data['patentnumber']}}</strong><strong>{{_doc.source}}&nbsp;</strong>
              </p>
              <span class="text-primary">{{_doc.data['applicant']}}{{_doc.data['AU']}}</span>&nbsp; <span *ngIf="_doc.data['RAW_CO']!=null && _doc.data['AF']==null" class="affiliation text-primary" [innerHTML]="'<br/>'+_doc.data['RAW_CO']"></span> <span *ngIf="_doc.data['AF']!=null" class="affiliation text-primary" [innerHTML]="'<br/>'+_doc.data['AF']"></span>
              <div *ngIf="_doc.data['HI']!=null" class="highlights" [innerHTML]="_doc.data['HI']"></div>
              <p id="header" class="darkgrey" [innerHTML]="_doc.header"></p>

          </div>
        </div>


        </button>
      </div>

      <div class="col-md-auto" *ngIf="false">
        <a href="https://naturaldevelopment.fr" target="_blank">
          <div class="overlayparent">
            <img class="media-object" style="max-width: 200px;" src="{{service_download + '/thumb/thumb' + _doc.doc+ '_1.png'}}">
            <div class="overlaychild" *ngIf=_doc.docveille>
              <p class="reserved">Reservé aux membres</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    
    <input type="hidden" name="func" value="exportzip" />
  </form>

  <!-- Alert réservée aux membres -->
  <div *ngIf="memberalert" class="alert alert-danger alert-dismissible show" role="alert" style="position: fixed; top: 200px; z-index: 99; left: 50%; margin-left: -230px;">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
      <use xlink:href="#exclamation-triangle-fill" /></svg>
    <strong>La consultation des documents de veille est reservée aux membres</strong><br /> <br /> <a href="https://naturaldevelopment.fr/home/plateforme-de-veille/" target="_blank">Consulter le site Natural development pour souscrire</a>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="onAlertclose()"></button>
  </div>

</div>

<div class="container_fluid" *ngIf="alert!=null" style="margin-top: 8px;">
  <div class="alert" [class.alert-danger]="isDanger" [class.alert-warning]="!isDanger" role="alert">{{alert}}</div>
  <div class="text-center">
    <img style="width: 400px;" src="assets/images/image-portail-nd.png">
  </div>
</div>

</ng-container>
V2.5
