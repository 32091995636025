<div id="mainwell" class="card card-body bg-light container-fluid" >
	<h2 class="welltitle">Charger des documents</h2>

	<div id="leftblock" class="col-md-6 form-horizontal" style="position:relative">
		<div class="box has-advanced-upload form-group">
		
		  <div id="dropzone" class="box__input" [class.isdragging]="isdragging" *ngIf="isinit"
		  	(drop)="drop($event)"
			(dragenter)="dragenter($event)"
			(dragover)="dragover($event)"
			(dragleave)="dragleave($event)"
			(dragend)="dragend($event)">
			
			<svg class="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path></svg>
			
		    <input id="file" class="box__file" type="file" multiple data-multiple-caption="{count} files selected" (change)="fileChangeEvent($event)" />
		    <label for="file"><span class="box__dragndrop">Glisser-déposer ici ou <span class="linklike">choisir les fichiers</span></span></label>
		  </div>
		  
		  <div id="box__uploading" class="box__uploading" *ngIf="isuploading" style="position:relative">Uploading&hellip;</div>
		  <div class="box__success" *ngIf="isdone">Upload done</div>
		  <div class="box__error" *ngIf="iserror">Error!</div>
		  
		</div>

		<div class="form-inline col-sm-12" *ngIf="isinit">
			
			<div class="form-group col-sm-12">
				<label> <input type="checkbox" id="indpurge" name="indpurge" [(ngModel)]="indpurge"> Purger les documents existants
				</label>
			</div>
		</div>

		<button *ngIf="(isinit && filesToUpload && filesToUpload.length>0)" class="box__button btn btn-primary btn-block" (click)="upload()">Upload</button>
	</div>
	
	<div class="col-md-6">
		<ul>
			<li *ngFor="let file of filesToUpload ; let i = index" style="list-style-type:none">
				<span class="col-md-9">#{{i+1}}&nbsp;{{file.name}}</span>
				<span class="col-md-2" style="text-align: right">{{formatSize(file.size)}}</span>
				<button id="btclear" class="btn btn-default col-md-1" (click)="onBtremovefile(i)" *ngIf="!isdone"> <span class="glyphicon glyphicon-remove"></span></button>
			</li>
		</ul>
	</div>
</div>
