import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoginService } from './login.service';

import { environment } from './../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TagService {

	constructor(public http: HttpClient) { }

	public gettags(typ: number, callback: Function): void {

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			responseType: 'json' as const
		};

		this.http.get(environment.service_gettag + "/" + typ, httpOptions)
			.subscribe((data: any) => {
				// console.log(JSON.stringify(data));
				callback(data.tags);
			});
	}
}
