export class Doc {
    public checked: boolean;
    public docveille: boolean        // data:DOCVEILLE exists ?

    constructor(
        public doc: string,
        public pdfname: string,
        public data: Object,
        public defattach: string,   // Fk of default attach
        public header: string,
        public source: string,
        public title: string,
        public lang: string,
        public content: string,
        public dat: Date|undefined,
        public rawcontent_b: boolean    //  rawcontent exists?
    ) {
        this.doc = doc;
        this.pdfname = pdfname;
        this.checked = false;
        if (data!=null) this.docveille = (data['KW'] && data['KW'].indexOf('DOCVEILLE') != -1);
    }

    getformatteddate(): string {
        return Doc.formatteddate( this.dat )
    }

//    getformatteddata(): string { // For futur use ... format all datafield
//        this.data.sort( function( a: any, b: any ) { return a.seq - b.seq })
//        let ret = ""
//        for ( let i = 0; i < this.data.length; i++ ) {
//            ret += this.data[i].value + "&nbsp;"
//        }
//        return ret
//    }

    static formatteddate( _date: any ): string {
        var datepart;
        if ( typeof _date == 'string' ) datepart = _date.split( 'T' )[0];
        /*if ( typeof _date == 'Date' ) datepart = _date.toLocaleString().split( 'T' )[0];*/
        else return null;

        datepart = _date.toLocaleString().split( 'T' )[0];
        var d = datepart.split( '-' );
        var day = d[2];
        var month = d[1];
        var year = d[0];
        return day + '/' + month + '/' + year;
    }
    
    getYear(): string {
        return Doc.year( this.dat )
    }
    
    static year( _date: any ): string {
        var datepart;
        if ( typeof _date == 'string' ) datepart = _date.split( 'T' )[0]
        /* else if ( typeof _date == 'Date' ) datepart = _date.toLocaleString().split( 'T' )[0]; */
        else return null;

        datepart = _date.toLocaleString().split( 'T' )[0];
        var d = datepart.split( '-' );
        var day = d[2];
        var month = d[1];
        var year = d[0];
        return year;
    }

}

