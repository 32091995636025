import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from "@angular/material/dialog";
import { UploaddocComponent } from './uploaddoc/uploaddoc.component';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		SearchresultComponent,
		LoginComponent,
		NavbarComponent,
		UploaddocComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,

		MatExpansionModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatToolbarModule,
		MatMenuModule,
		MatIconModule,
		MatDividerModule,
		MatListModule,
		MatSelectModule,
		MatCardModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatCheckboxModule,
		MatDialogModule,

		RouterModule.forRoot([
			{ path: 'search/:keyword', component: SearchresultComponent },	// search path
			{ path: "", component: SearchresultComponent },									// Index path, no param
			{ path: "uploaddoc", component: UploaddocComponent },
			{ path: "**", component: SearchresultComponent }								// Wildcard route
		]),

	],
	providers: [],
	bootstrap: [AppComponent]

})
export class AppModule { }
