export const environment = {
	production: false,
	"service_solrsuggest_gportal": "http://localhost:8983/solr/gportal/terms?terms.fl=contentsuggest&wt=json&terms.mincount=2&omitHeader=true&terms.prefix=",
	"service_solrsuggest_cfatale": "http://localhost:8983/solr/gportal_cfatale/terms?terms.fl=contentsuggest&wt=json&terms.mincount=2&omitHeader=true&terms.prefix=",
	"service_solrsuggest": "http://localhost:8983/solr/gportal_natural/terms?terms.fl=contentsuggest&wt=json&terms.mincount=2&omitHeader=true&terms.prefix=",

	"service_getdocjson": "https://veille.naturaldevelopment.fr/service/getdocjson",
	"service_gettag": "https://veille.naturaldevelopment.fr/service/gettag",
	"service_savedoc": "https://veille.naturaldevelopment.fr/service/savedoc",
	"service_uploaddoc": "https://veille.naturaldevelopment.fr/service/uploaddoc",
	"service_solraction": "https://veille.naturaldevelopment.fr/service/solraction",
	"service_download": "https://veille.naturaldevelopment.fr/service/download",	
	"service_downloadzip": "https://veille.naturaldevelopment.fr/service/downloadzip",
	"service_downloadzip_linkroot": "https://veille.naturaldevelopment.fr/service",
	"service_getgroups": "https://veille.naturaldevelopment.fr/service/getgroups",
	"service_login": "https://veille.naturaldevelopment.fr/service/login",
	"service_getdoc": "https://veille.naturaldevelopment.fr/service/getdoc",
	"service_getfulltext": "https://veille.naturaldevelopment.fr/service/getdoc",
	"service_getattach": "https://veille.naturaldevelopment.fr/service/getattach",
	"service_genthumb": "http://localhost:8090/service/genthumb",
	
	"home_logo": "icone_nd.png",
	"home_title": "Bienvenue sur la Plateforme de Veille Packaging de Natural Development",
	"home_subtitle": "<span class=\"badge\">485</span> scientific papers",
	"searchfields": "xxx"
};