import { Component, OnInit } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { LoginService } from '../login.service';
import { SolrResponse } from '../shared/solrResponse';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	public email: string;
	public password: string;
	public message: string;

	constructor(public loginService: LoginService, public http: HttpClient, public route: ActivatedRoute) {
		this.message = "";
	}

	ngOnInit(): void {
		// console.log("localStorage.getItem('login') " + localStorage.getItem('login'));
		if (localStorage.getItem('login') === null) {	// No request for manual login, automatic login
			this.email = "guest";
			this.password = "guest"
			this.onSubmit();
		} else {	// Request for manual login
			localStorage.removeItem('login');	// Clear request
		}
	}

	public onSubmit() {
		if (!this.email) {	// Empty email => auto login
			this.email = "guest";
			this.password = "guest"
		}
		this.loginService.login(this.email, this.password);
	}

	public onClick(): void {
		console.log("onClick");
		this.loginService.wrongpassword = false;
	}
}
