import { Injectable } from '@angular/core';
//import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from './../environments/environment';

import { LoginService } from './login.service';
import { SolrResponse } from './shared/solrResponse';
import { Doc } from './shared/doc';

@Injectable({
	providedIn: 'root'
})
export class SearchresultService {

	private solrResponse: Subject<SolrResponse> = new Subject<SolrResponse>();
	subject = new Subject();
	// Observable SolrResponse streams
	solrResponse$ = this.solrResponse.asObservable();

	constructor(private http: HttpClient, private loginService: LoginService) { }

	public search(input: string, selectedGroup: number, source: string, lang: string, sensibility: string, datdeb: string, datfin: string, start: number, rows: number, freeonly: boolean, cb: Function): void {
		let q: string = this.getquery(input, selectedGroup, source, lang, sensibility, datdeb, datfin, start, rows, freeonly);
		console.log("search : " + environment.service_solraction + q);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.loginService.getToken()}`
			}),
			responseType: 'json' as const
		};

		this.http.get<SolrResponse>(environment.service_solraction + '/select' + q, httpOptions)
			.subscribe(
				(sr: SolrResponse) => {
					this.solrResponse.next(sr);
					// console.log("json : " + JSON.stringify(sr.response, null, 4));
					cb(sr.response.numFound)
				},
				(error: any) => { console.log("Error happened: " + error); cb(-1) }
				// ,() => { console.log("the subscription is completed"); cb()}
			);
	}

	private getquery(input: string, selectedGroup: number, source: string, lang: string, sensibility: string, datdeb: string, datfin: string, start: number, rows: any, freeonly: boolean): string {	// Build SolR query based on user request
		let q: string = "";
		let qroot, qdat;
		let squote = '%27';
		let dquote = '%22';
		let leftp = '%28'
		let rightp = '%29'

		let fl = "&fl=id,pdfname,defattach,header,source,title,lang,dat,patentnumber,data,gp,rawcontent_b,score"  // Field list to retrieve from Solr. No more, no less
		let qf = "&defType=edismax&qf=title^4 header^2 contentsuggest^.1"  // EDismax 
		let paging = "&start=" + start + "&rows=" + rows
		let highlighting = "&hl=true&hl.fl=header,title&hl.fragsize=0&hl.simple.pre=<mark>&hl.simple.post=</mark>&hl.mergeContiguous=true"

		if (input === "NDV") {	// "Home page" query, order by dat desc
			qroot = "?sort=dat desc" + fl + paging + highlighting + qf + "&q="                   // Text searchbox, mandatory
		} else {	// Other query, order by score desc
			qroot = "?sort=score desc" + fl + paging + highlighting + qf + "&q="                 // Text searchbox, mandatory	
		}


		let nbwords = input.trim().split(" ").length
		let proximity = (nbwords * nbwords) + 1

		qdat = "";

		if (datdeb || datfin) { // Date range ex : dat:[2016-03-24T00:00:00Z TO *]
			var from = '*';   // Default values
			var to = '*';
			if (datdeb) {
				from = this.getFormattedDate(datdeb);
			}
			if (datfin) {
				to = this.getFormattedDate(datfin);
			}
			qdat = ' AND dat:[' + from + ' TO ' + to + ']';
		}

		if (input == '' || input == null) q = qroot + 'contentsuggest:* OR DOCVEILLE^20 ' + qdat;
		else {
			if (sensibility == 's1') {        // Exact phrase match
				q = qroot + '(contentsuggest:' + dquote + input + dquote + 'OR header:' + dquote + input + dquote + '^2 OR title:' + dquote + input + dquote + '^4)'
			} else if (sensibility == 's2') { // Easy mode Proximity ~pmul * nbwords
				q = qroot + '(contentsuggest:' + dquote + input + dquote + '~' + proximity + ' OR header:' + dquote + input + dquote + '~' + proximity + '^2 OR title:' + dquote + input + dquote + '~' + proximity + '^4)';
			} else if (sensibility == 's3') { // Proximity ~10 (removed)
				q = qroot + dquote + input + dquote + '~10';
			} else if (sensibility == 's4') { // FULL mode. AND connector => ()  ND custom
				// q = qroot + leftp +'contentsuggest:' +leftp + input + rightp + rightp;   // StandardQueryParser exemple
				if (freeonly) {	// Free doc only => exclude data:DOCVEILLE
					q = qroot + '%28' + input + '%29 AND -data:DOCVEILLE';
				} else {	// General case, include DOCVEILLE w/ special boost
					q = qroot + leftp + leftp + leftp + input + rightp + qdat + rightp + ' AND -data:DOCVEILLE' + rightp + ' OR ' + leftp + leftp + leftp + input + rightp + qdat + rightp + ' AND data:DOCVEILLE^20' + rightp;   // ND custom, boost DOCVEILLE
				}

				// q = qroot +  input ;   // EDismaxQueryParser 
			} else if (sensibility == 's5') { // Personnalisé => ()
				q = qroot + '%28' + input + '%29';
			}
			// q += input;
		}

		if (freeonly) q += ' AND NOT %22NDV%22'; // Free docs only means no NDV

		if (source != null && source != '' && source != '*') {    // Source input field
			q += ' AND source:%22' + source + '%22';
		}

		if (lang != null && lang != '' && lang != '*') {  // Lang input field
			q += ' AND lang:%22' + lang + '%22';
		}

		if (datdeb || datfin) { // Date range ex : dat:[2016-03-24T00:00:00Z TO *]
			var from = '*';   // Default values
			var to = '*';
			if (datdeb) {
				from = this.getFormattedDate(datdeb);
			}
			if (datfin) {
				to = this.getFormattedDate(datfin);
			}
			qdat = ' AND dat:[' + from + ' TO ' + to + ']';
		}
		//q += " AND gp:" + selectedGroup // No more groups

		if (sensibility == 's4') { // AND connector added 
			q += "&q.op=" + "AND";
		}
		// console.log(q)
		return q;
	}

	/**
		* Zip download
		*/
	public zipdownload(docs: Array<Doc>): boolean {
		console.log("downloadzip service called ");
		// console.log(docs);
		let ret: boolean = true;

		let headers = new Headers({ 'Content-Type': 'application/json' });
		headers.append('Authorization', `Bearer ${this.loginService.getToken()}`);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}).append('Authorization', `Bearer ${this.loginService.getToken()}`)
		};



		console.log("MakePost");

		this.http.post( // Post the array of docs
			environment.service_downloadzip,
			JSON.stringify({
				docs: docs
			}),
			httpOptions)
			.subscribe((res: any) => {   // Kinda' ugly workaround to simulate a click on a link, to open a tab 
				console.log(JSON.stringify(res));
				var data = res.data;
				if (data !== null) {
					let service: string = data.service;
					let filename: string = data.filename;
					let url = environment.service_downloadzip_linkroot + '/' + service + '/' + filename;
					console.log(url);
					var linkElement = document.createElement('a');
					linkElement.setAttribute('href', url);
					linkElement.setAttribute('target', '_blank');
					document.body.appendChild(linkElement);
					//                linkElement.setAttribute("download", filename);
					//                
					var clickEvent = new MouseEvent("click", {
						"view": window,
						"bubbles": true,
						"cancelable": false
					});
					linkElement.dispatchEvent(clickEvent);
				}
				ret = true;
			});
		return ret;
	}
	/**
	 * Helper method for Zip download
	 */
	private makeDownloadzipPost(docs: Array<Doc>, cb: Function): void {
		cb(true);
	}

	public getFormattedDate(_date: string) {
		var d = _date.split('/');
		//        if (d.length<>3) {
		//            
		//        }
		var day = d[0];
		var month = d[1];
		var year = d[2];
		return year + '-' + month + '-' + day + 'T00:00:00Z';
	}

	private handleError<T>(operation = 'Unknown operation', result?: T) {
		return (error: any): Observable<T> => {

			// TODO: send the error to remote logging infrastructure
			console.error('handleError: ' + JSON.stringify(error)); // log to console instead

			// TODO: better job of transforming error for user consumption
			// this.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}


}
